.cursor {
    width: 50px;
    height: 50px;
    position: absolute;
    opacity: 1;
    transform: translate(-25px, -25px);
    mix-blend-mode: multiply;
    
    
  
    &.hidden {
      opacity: 0;
    }
  
    svg {
      transform: translate(-40%, -40%);
    }
  
    div {
      position: absolute;
      
      
    }
}