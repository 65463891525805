@import "../styles/globals.scss";

.pull {
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
}

.inner {
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  // img {
  //   width: 65%;
  //   height: auto;
  // }
}



