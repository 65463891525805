@import "../styles/globals.scss";

.container {
  height: 100vh;
  overflow-y: scroll;
  touch-action: none;
  cursor: none;
}

.infoContainer {
  width: calc(100% - 120px);
  height: calc(100% - 200px);
  position: absolute;
  left: 60px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  cursor: none;

  @include sm {
    flex-direction: row;
  }
}

.section {
  flex-grow: 1;
  width: 100%;
  padding: 10px;
  cursor: none;

  p {
    font-family: $body;

    cursor: none;

    @include sm {
    }
  }

  a {
    display: block;
    cursor: none;
    padding-bottom: 10px;

    @include sm {
    }
  }

  @include sm {
    width: 50%;
    padding: 30px;
  }
}
