@import "../styles/globals.scss";

.main {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        height: 80%;
        max-width: 90%;
    }
}