@import "./breakpoints.scss";
@import "./colors.scss";
@import "./type.scss";

html,
body {
  padding: 0;
  margin: 0;
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  cursor: none;
  }

*::-webkit-scrollbar {
    width:0px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

a, p, pre, h1, h2, body {
    font-family: $body;
    font-size: 18px;
    cursor: none;

    @include sm {
        font-size: 28px;
    }
}
