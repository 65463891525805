@import "../styles/globals.scss";

.gallery {
    padding-top: 40px;
    width: calc(100% - 120px);
    min-height: calc(100% - 200px);
    position: absolute;
    left: 60px;
    top: 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.imageContainer {

    display: inline;
    pointer-events: all;

    &.fullWidthContainer {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 40px;
    }

    &.inlineWidthContainer {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;

        &:nth-of-type(even) {
            padding-top: 5%;
        }

        &:nth-of-type(odd) {
            padding-bottom: 20%;
        }

        @include sm {
            width: 50%;
        }
        
    }

    p {
        width: 100%;
        position: relative;
        opacity: 0;

    }

    &:hover {
        p   {
            opacity: 1;
        }
    }
}