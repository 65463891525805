@font-face {
    font-family: 'pangram-right';
    src: url('./fonts/PPRightGrotesk-Regular.eot');
    src: url('./fonts/PPRightGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
         url('./fonts/PPRightGrotesk-Regular.woff2') format('woff2'),
         url('./fonts/PPRightGrotesk-Regular.woff') format('woff'),
         url('./fonts/PPRightGrotesk-Regular.ttf') format('truetype'),
         url('./fonts/PPRightGrotesk-Regular.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}

$body: 'pangram-right', sans-serif;