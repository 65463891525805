@import "../styles/globals.scss";

.container {
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  touch-action: none;
  // cursor: none;
}

.description {
  position: absolute;
  max-height: 200px;
  width: 90%;
  left: 5%;
  bottom: 0px;
  padding-bottom: 40px;
  text-align: center;

  @include sm {
    width: 70%;
    left: 15%;
  }

  p {
    
    @include sm {

    }
  }
}


.bar {
  position: absolute;
  width: 100vw;
  pointer-events: none;
  z-index: 13;
}

.navContainer {
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  z-index: 12;
}

.navInner {
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
}

.leftButton {
    position: absolute;
    height: 60px;
    left: 0px;
    transform-origin: top left;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  
    a {
      font-family: $body;
      font-size: 18px;
      pointer-events: all;
      cursor: none;
  
      @include sm {
          font-size: 28px;
      }
    }
  }
  
  .rightButton {
    position: absolute;
    height: 60px;
    top: -60px;
    transform-origin: bottom left;
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  
    a {
      font-family: $body;
      font-size: 18px;
      pointer-events: all;
      cursor: none;
  
      @include sm {
          font-size: 28px;
      }
    }
  }

.prompt {
  width: 100%;
  height: auto;
  position: relative;
  bottom: 10%;
  text-align: center;
  font-size: 15px;
  cursor: none;
  opacity: 1;
  transition: opacity 0.6s ease;

  @include sm {
    bottom: 25%;
    font-size: 18px;
    
  }

  &.hidden {
    opacity: 0;
  }
}