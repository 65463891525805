@import "../styles/globals.scss";

.leftButton {
    position: fixed;
    height: 60px;
    left: 0px;
    transform-origin: top left;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  
    a {
      pointer-events: all;
    //   cursor: none;
  
      @include sm {
          font-size: 28px;
      }
    }
  }
  
  .rightButton {
    position: fixed;
    height: 60px;
    top: -60px;
    transform-origin: bottom left;
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  
    a {
      pointer-events: all;
    //   cursor: none;
  
      @include sm {
          font-size: 28px;
      }
    }
  }